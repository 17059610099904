import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
  }

  disconnect() {
  }

  onPostError(e) {
    console.log(e);
    const [data] = e.detail;
    const errors  = data.errors;

    this._resetErrorFields();

    for (var key in errors) {
      const errorField = this.element.querySelector(`.help.${key}_error`);
      if (errorField) {
        errorField.textContent = errors[key].join(', ');
      }
    }
  }

  onPostSuccess(e) {
    window.location = this.data.get("redirect");
  }

  _resetErrorFields() {
    Array.from(this.element.getElementsByClassName('help is-danger')).forEach(function(elt){
      elt.textContent = "";
    });
  }
}
